.stagger-it {
    color: #9E9E9E;
  
    .char {
      transition: color 0.025s ease-in-out;
      transition-delay: calc( 0.025s * var(--char-index) );
    }
    
    &.read { color: #ffffff; }
  
}