// 

:root {
    --cursor-red: url('../images/cursor-red.svg')  10 10, auto;
    --cursor-white: url('../images/cursor-white.svg') 10 10, auto;
    --cursor-gray: url('../images/cursor-gray.svg') 10 10, auto;

    --corventis-cursor: var(--cursor-red);
}

body {
    cursor: var(--corventis-cursor);

    & a {
        cursor: inherit !important;

        &:hover svg .cls-1 { stroke: #7c2233; }
    }
}

.cursor-white {
    background-color: #ffffff !important;
    border: 1px solid #efefef;
}

.cursor-red {
    background-color: #7c2233 !important;
    border: 1px solid #953a47;
}

.cursor-gray {
    background-color: #37373c !important;
    border: 1px solid #1f1f23;
}
  
.pointerEventsNone { pointer-events: none; }

#follower {
    width: 20px;
    height: 20px;

    position: absolute;
    top: 0px;
    left: 0px;

    border-radius: 2px;
    z-index: 10000;

    pointer-events: none;

    transition: background-color 0.125s ease-in-out, border-color 0.05s ease-in-out;
}