@import "node_modules/bootstrap/scss/_utilities";
@import "node_modules/bootstrap/scss/_variables";

$min-vw: 100px;
$min-vh: 2000px;
$min-font-size: 10px;

$max-font-size-2x2: 25.5px;
$max-font-size-4x2: 52px;

$font-size-property: font-size;
$line-height-property: line-height;

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0px;
}

.ratio4x2 {
  & p {
    margin-bottom: 0px !important;
    @include fluid-type(
      $font-size-property,
      $min-vw,
      $min-vh,
      $min-font-size,
      $max-font-size-4x2
    );
    line-height: 1.475;
    letter-spacing: 0.21px;

    & b,
    strong {
      letter-spacing: 0.94px;
    }
  }
}

.ratio2x2 {
  & p,
  a {
    margin-bottom: 0px !important;
    @include fluid-type(
      $font-size-property,
      $min-vw,
      $min-vh,
      $min-font-size,
      $max-font-size-2x2
    );
    line-height: 1.425;
    letter-spacing: 0.14px;

    & b,
    strong {
      letter-spacing: 0.63px;
    }
  }

  & a {
    font-weight: 500;
  }
}

.ratio1x1 {
  & h2 {
    @include fluid-type(
      $font-size-property,
      $min-vw,
      $min-vh,
      $min-font-size,
      22px
    );
  }

  & h4 {
    @include fluid-type(
      $font-size-property,
      $min-vw,
      $min-vh,
      $min-font-size,
      28px
    );
  }

  & p {
    @include fluid-type(
      $font-size-property,
      $min-vw,
      $min-vh,
      $min-font-size,
      16px
    );
  }
}

address {
  display: block;
  @include fluid-type(
    $font-size-property,
    $min-vw,
    $min-vh,
    $min-font-size,
    $max-font-size-2x2
  );
  line-height: 1.425;
  letter-spacing: 0.14px;

  &.black {
    color: #000000;
  }

  &.white {
    color: #ffffff;
  }

  &.red {
    color: #871631;
  }
}

.collapsibleContent {
  & p {
    line-height: 1.425;
    letter-spacing: 0.14px;
    @include fluid-type(
      $font-size-property,
      $min-vw,
      $min-vh,
      $min-font-size,
      $max-font-size-2x2
    );
  }

  & b,
  strong {
    letter-spacing: 0.63px;
  }

  & ul {
    & li {
      line-height: 1.48;
    }
  }

  & ol {
    & li {
      &::before {
        @include fluid-type(
          $font-size-property,
          $min-vw,
          $min-vh,
          $min-font-size,
          $max-font-size-2x2
        );
      }
    }
  }
}

sup {
  vertical-align: text-top;
  font-weight: 600;
  top: 0;
  margin-right: 5rem;
  text-transform: uppercase;
}

sub {
  font-weight: 500;
  margin-right: 1.25rem;
  bottom: 2px;
  text-transform: uppercase;
}

.text-u1,
.text-u2 {
  line-height: 1;
}

.ck-paragraph,
.ck-paragraph-f1 {
  margin-bottom: 0px;
  span {
    margin-bottom: 0px !important;
  }
}

.ck-highlighted-text {
  background-color: var(--simplikon-color-main);
  color: white;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.ck-paragraph-smaller,
.ck-paragraph-smaller span {
  font-size: 1em !important;
}
