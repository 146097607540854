@import "variables";
@import "node_modules/bootstrap/scss/bootstrap";
@import "node_modules/@fortawesome/fontawesome-free/css/all";
// @import "calculated";
@import "fluidtypography";
@import "text";

@import "corventis-grid";
@import "corventis-cursor";
@import "stagger-it";

@import "mixins";

html,
body {
  margin: 0;
}

body {
  font-family: "Apercu Pro";
  font-weight: 400;
  font-style: normal;
}

a {
  & .chevron-right {
    margin-right: 0.75rem;
  }
}

b,
strong {
  font-weight: 500;
}

button {
  -webkit-appearance: none !important;
  border-radius: 0px !important;
}

$optional-color: #d8dadb;

.magnifier {
  display: block;

  width: 51px;
  height: 51px;

  &:before {
    content: "";
    display: inline-block;

    width: 100%;
    height: 100%;

    background-position: center center;
  }
}

.collapsibleContent {
  & ul {
    padding-left: 0rem !important;
    list-style: none;

    & li {
      &::before {
        content: "";
        display: inline-block;
        width: 14px;
        height: 14px;
        // background: url('../images/shevron-right.svg') top center no-repeat;
        margin-right: 14px;
        vertical-align: middle;
      }
    }
  }

  & ol {
    list-style: none;
    padding-left: 0rem;
    counter-reset: ordered-list-counter;

    line-height: 1.5;

    & li {
      margin-bottom: 1rem;
      counter-increment: ordered-list-counter;

      &::before {
        content: counter(ordered-list-counter) ".";
        display: inline;
        margin-right: 5px;
      }
    }
  }
}

.bodyWrapper {
  // height: 100vh;
  // overflow-y: scroll;
  // scroll-snap-type: y mandatory;

  // scroll-behavior: smooth;
}

.light-red {
  background-color: #f6f3f3 !important;
  color: #181818 !important;

  & .stagger-it {
    color: #9e9e9e;

    &.read {
      color: var(--bs-body-color);
    }
  }
}

.dark-red {
  background-color: #871631 !important;
  color: white !important;
}

.light-gray {
  background-color: #d8dadb !important;
  color: #181818 !important;

  & .stagger-it {
    color: #9e9e9e;

    &.read {
      color: var(--bs-body-color);
    }
  }
}

.dark-gray {
  background-color: #37373c !important;
  color: #ffffff !important;
}

header {
  & .container:first-child {
    padding-top: 8px;
  }
  & .container.grid {
    margin-top: 0px !important;
  }
}

.container:first-child,
.container.subchapter {
  scroll-snap-align: start;
  scroll-margin-top: 6px;
}

.subchapter {
  height: 2px !important;

  &::before {
    content: "\ad";
    width: 0px;
    line-height: 1.2;
    font-size: 1rem;
    color: white !important;
  }

  & h2 {
    width: fit-content;

    margin-left: 10px;
    margin-right: 10px !important;

    margin-top: -7px;
  }

  & hr {
    border: 1px solid #37373c;
    opacity: 1 !important;
  }
}

.grid {
  & .media-bg {
    position: absolute;
    top: 0px;
    left: 0px;

    max-height: calc(100% + 40px);
    // width: auto;
  }
}

.d-flex.justify-content-center.align-items-center {
  & *:not(img):not(.logo):not(.first-upload-icon):not(.loader-icon) {
    // width: 100%;
  }
}

.text-ident {
  & p {
    text-indent: 40px;
  }
}

.public-preview__preview-container {
  height: 100% !important;
}
.public-preview__toolbar {
  display: none !important;
}
