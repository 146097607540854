
$grid-gap: 20px;

.grid {  
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: $grid-gap;
    
    width: 100%;

    padding: 20px;

    &.grid3x3 {
        height: 100%;
        width: 100% !important;
        grid-template-columns: repeat(3,1fr);
        grid-template-rows: repeat(3,1fr);

        padding: 0px !important;
    }

    &.grid2x2 {
        height: 100%;
        width: 100% !important;
        grid-template-columns: repeat(2,1fr);
        grid-template-rows: repeat(2,1fr);

        padding: 0px !important;
    }

    &.grid2x1 {
        height: 100%;
        width: 100% !important;
        grid-template-columns: repeat(2,1fr);
        grid-template-rows: repeat(1,1fr);

        padding: 0px !important;
    }

    &.grid1x1 {
        height: 100%;
        width: 100% !important;
        grid-template-columns: repeat(1,1fr);
        grid-template-rows: repeat(1,1fr);

        padding: 0px !important;
    }

    &.grid1x2 {
        height: 100%;

        grid-template-columns: repeat(2,1fr);
        grid-template-rows: repeat(2,1fr);

        padding: 0px !important;
    }

    &.grid3x4 {
        height: 100%;
        width: 100% !important;

        grid-template-columns: repeat(3,1fr);
        grid-template-rows: repeat(4,1fr);

        padding: 0px !important;
    }

    &.cl-1 {
        // grid-template-rows: repeat(2,1fr) 1fr;
        grid-template-rows: repeat(2,1fr);
        grid-template-columns: repeat(1,1fr);

        grid-template-areas: none;
    }
}
  
.block {
    position: relative;
    line-height: 0;

    &.collapsing {
        height: 0;
        overflow: hidden !important;

        padding-left: 3 * $grid-gap !important;
        padding-right: 3 * $grid-gap !important;

        &::before { content: none; }
    }
    
    &.collapse {
        padding-left: 3 * $grid-gap !important;
        padding-right: 3 * $grid-gap !important;

        &::before { content: none; }
    }

    &::before {
        content: "";
        display: inline-block;
        width: 1px;
        height: 0px;
    }

    & .content {
        // background-color: red;
        position: absolute;
        top: 0;
        left: 0;
        
        width:100%;
        height: 100%;
        
        overflow: hidden;
        line-height: 1.5;

        & .content-padding {
            padding: 2 * $grid-gap - 6 2 * $grid-gap - 4;
        }

        & .content-horizontal-padding {
            padding-top: 2 * $grid-gap - 6;
        }
    }
}

footer {
    padding-left: $grid-gap;
    padding-right: $grid-gap;
}

.ratio-scale-h-50 {
    & .block::before { padding-bottom: 50% !important;}
}

.ratio-scale-h-25 {
    & .block::before { padding-bottom: 25% !important;}
    &.block::before { padding-bottom: 25% !important;}
}

.ratio-scale-h-1x3 {
    & .block::before { padding-bottom: 33.3% !important;}
}


ol {
    & li { text-indent: 28px; }
}

@function block-width($grid-gap, $i) {
    @return (100% - ($grid-gap * ($i - 1))) / $i;
  }

@mixin before-padding($i, $j) {
    @if $i > $j {
        padding-bottom: calc($j * ((100% - ($grid-gap * ($i - 1))) / $i) + ($grid-gap * ($j - 1)) );
    } @else {
        padding-bottom: calc(100% / ($i/$j));
    }
}

@for $i from 1 through 4 {
    @for $j from 1 through 4 {
        .ratio#{$i}x#{$j} {
            grid-column: span $i; grid-row: span $j;
            &::before { @include before-padding($i, $j)}
        }
    }
}

.ratio4x {
    grid-column: span 4; grid-row: auto;
    &::before { content: none !important; }

    & > .content {
        position: relative !important;
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }
}

.ratio3x {
    grid-column: span 3;
    grid-row: auto;
    &::before { content: none !important; }

    & > .content {
        position: relative !important;
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }
}

.ratio2x {
    grid-column: span 2; grid-row: auto;
    &::before { content: none !important; }

    & > .content {
        position: relative !important;
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }
}

.ratio1x {
    grid-column: span 1; grid-row: auto;
    &::before { content: none !important; }

    & > .content {
        position: relative !important;
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }
}

.subchapter hr {
    margin-left: $grid-gap;
    margin-right: $grid-gap;
}