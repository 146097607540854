@use 'sass:math';

@function strip-unit($value) {
  @return math.div($value, ($value * 0 + 1));
}

@mixin fluid-type($property, $min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      #{$property}: $max-font-size !important;
      min-height: 0.00001vw;
      
      @media screen and (min-width: $min-vw) {
        #{$property}: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})) !important;
      }

      @media screen and (min-width: $max-vw) {
        #{$property}: $max-font-size !important;
      }
    }
  }
}

@mixin fluid-margin-top-dropdown($min-vw, $max-vw, $min-font-size, $max-font-size, $padding-top, $gap) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      margin-top: $max-font-size !important;
      
      @media screen and (min-width: $min-vw) {
        margin-top: calc((#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}) + #{$padding-top} + #{$gap}) * -1) !important;
      }

      @media screen and (min-width: $max-vw) {
        margin-top: calc(($max-font-size +  + #{$padding-top} + #{$gap}) * -1) !important;
      }
    }
  }
}